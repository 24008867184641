const Data = [
  {
    title: "24 Hour Troubleshooting",
    description: "HostBuddy is available all hours of the day to intelligently troubleshoot critical guest issues, following your procedures and guidelines.",
    messages: [
      {
        text: "There's a red pickup truck in my parking space!",
        sender: "guest",
        timeDate: "6/27 12:03 AM",
        sendBy: "Guest",
      },
      {
        text: "I paid for parking in advance specifically to avoid this",
        sender: "guest",
        timeDate: "6/27 12:03 AM",
        sendBy: "Guest",
      },
      {
        text: "Hi Emma, I'm sorry about the parking issue! As a temporary solution there is a 24-hour parking lot at 1047 B St, just a three-minute walk away. We will reimburse you for any parking fees. Please let me know if you need further assistance.",
        sender: "host",
        timeDate: "6/27 12:04 AM",
        sendBy: "HostBuddy",
      },
      {
        text: "ummm I don't feel safe walking around here at night",
        sender: "guest",
        timeDate: "6/27 12:06 AM",
        sendBy: "Guest",
      },
      {
        text: "I understand your concern, Emma. For now, please use parking space #1. Our cleaners use this space in the mornings, so we would be grateful if you could move your car by 10 AM.",
        sender: "host",
        timeDate: "6/27 12:07 AM",
        sendBy: "HostBuddy",
      },
      {
        text: "Ok. Thank you",
        sender: "guest",
        timeDate: "6/27 12:07 AM",
        sendBy: "Guest",
      },
      {
        text: "You're welcome! Have a great stay.",
        sender: "host",
        timeDate: "6/27 12:08 AM",
        sendBy: "HostBuddy",
      },
    ],
  },
  {
    title: "Inquiry Handling",
    description: "Equipped with details about your properties, HostBuddy can answer guest inquiries and provide information about your listings. HostBuddy can communicate in any language, right out of the box.",
    messages: [
      {
        text: "Hallo Was kann man machen in zermatt zu dieser Zeit? Ist die Wohnung zentral gelegen? Kann man Skifahren in Zermatt und wo kann ich mieten? Hat die Wohnung eine Waschmaschine? Danke für eine Antwort",
        translatedText: "Hello, what can one do in Zermatt at this time? Is the apartment centrally located? Can you ski in Zermatt and where can I rent? Does the apartment have a washing machine? Thanks for an answer",
        sender: "guest",
        timeDate: "9/10 2:32 PM",
        sendBy: "Guest",
      },
      {
        text: "Hallo! Ja, die Wohnung ist sehr zentral gelegen, nur 4 Gehminuten vom Sunnegga Express entfernt. Zermatt bietet ganzjährig Gletscherskifahren, aber die Hauptskisaison beginnt normalerweise Ende November. Es gibt mehrere Skiverleihgeschäfte in der Stadt. Die Wohnung ist mit einer Waschmaschine ausgestattet. Zermatt bietet viele Aktivitäten wie Wandern, Mountainbiken und Sightseeing. Wenn Sie weitere Informationen benötigen, fragen Sie gerne!",
        translatedText: "Hello! Yes, the apartment is very centrally located, just a 4-minute walk from the Sunnegga Express. Zermatt offers year-round glacier skiing, but the main ski season usually starts at the end of November. There are several ski rental shops in town. The apartment is equipped with a washing machine. Zermatt offers many activities such as hiking, mountain biking, and sightseeing. If you need more information, feel free to ask!",
        sender: "host",
        timeDate: "9/10 2:33 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "Was macht diese Wohnung speziell? Gibt es etwas zu beachten wenn man nach zermatt reist? Wir sind eine Familie mit Grosseltern und Kinder. Ist die Unterkunft einfach zugänglich? Gibt es ein Babybett in der Unterkunft?",
        translatedText: "What makes this apartment special? Is there anything to consider when traveling to Zermatt? We are a family with grandparents and children. Is the accommodation easily accessible? Is there a baby cot in the accommodation?",
        sender: "guest",
        timeDate: "9/10 2:43 PM",
        sendBy: "Guest",
      },
      {
        text: "Die Wohnung besticht durch ihren Matterhorn-Blick und zentrale Lage! Wichtig zu wissen: Zermatt ist autofrei, und die Wohnung ist über 115 Stufen erreichbar - das könnte für Großeltern herausfordernd sein. Ein Reisebettchen für Babys ist vorhanden. Die Wohnung bietet viel Platz für Familien, aber bitte bedenken Sie die Zugänglichkeit. Haben Sie noch Fragen zur Unterkunft oder Ihrer Reise nach Zermatt?",
        translatedText: "The apartment impresses with its Matterhorn view and central location! Important to know: Zermatt is car-free, and the apartment is accessible via 115 steps - this could be challenging for grandparents. A travel cot for babies is available. The apartment offers plenty of space for families, but please consider the accessibility. Do you have any other questions about the accommodation or your trip to Zermatt?",
        sender: "host",
        timeDate: "9/10 2:44 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "wie genau kommen wir mit dem Auto zur WOhnung?",
        translatedText: "How exactly do we get to the apartment by car?",
        sender: "guest",
        timeDate: "9/10 2:47 PM",
        sendBy: "Guest",
      },
      {
        text: "Guten Tag! Zermatt ist eine autofreie Stadt. Sie müssen Ihr Auto in Täsch parken und von dort einen 12-minütigen Shuttle-Zug nach Zermatt nehmen. In Zermatt können Sie dann ein Elektrotaxi zur Wohnung nehmen. Benötigen Sie weitere Informationen zur Anreise?",
        translatedText: "Good day! Zermatt is a car-free town. You have to park your car in Täsch and take a 12-minute shuttle train from there to Zermatt. In Zermatt, you can then take an electric taxi to the apartment. Do you need more information about the arrival?",
        sender: "host",
        timeDate: "9/10 2:48 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "Können sie mir ein Taxi Firma empfehlen bitte",
        translatedText: "Can you recommend a taxi company to me, please?",
        sender: "guest",
        timeDate: "9/10 2:51 PM",
        sendBy: "Guest",
      },
      {
        text: "In Zermatt finden Sie elektrische Taxis direkt am Bahnhof. Bitten Sie den Fahrer einfach, Sie zum Belmont House, Wiestistrasse 210, zu bringen. Die Taxis sind leicht zu finden und eine bequeme Option für Ihre Familie!",
        translatedText: "In Zermatt, you will find electric taxis directly at the train station. Just ask the driver to take you to Belmont House, Wiestistrasse 210. The taxis are easy to find and a convenient option for your family!",
        sender: "host",
        timeDate: "9/10 2:51 PM",
        sendBy: "HostBuddy",
      },
    ],
  },
  {
    title: "Smart Deferral",
    description: "HostBuddy understands the limits of its abilities. You can choose from a range of different ways for HostBuddy to handle situations that require human intervention, and you can opt to receive a notification over email, text, or Slack.",
    messages: [
      {
        text: "Hey Michael, we'll be arriving a couple hours before expected. Could we check in early? Totally understand if not.",
        sender: "guest",
        timeDate: "7/02 7:10 AM",
        sendBy: "Guest",
      },
      {
        text: "Hi Scott! Early check-in may be possible, but depends on our cleaners' schedule. Could you please confirm what time you'll be arriving? We'll do our best to accommodate you.",
        sender: "host",
        timeDate: "7/02 7:11 AM",
        sendBy: "HostBuddy",
      },
      {
        text: "Thanks for the fast reply. Around 2pm",
        sender: "guest",
        timeDate: "7/02 7:13 AM",
        sendBy: "Guest",
      },
      {
        text: "Thanks! I'll check with our team and get back to you shortly about availability.",
        sender: "host",
        timeDate: "7/02 7:13 AM",
        sendBy: "HostBuddy",
      },
      {
        text: "Thank you",
        sender: "guest",
        timeDate: "7/02 7:14 AM",
        sendBy: "Guest",
      },
      {
        text: "You're welcome! We look forward to hosting you.",
        sender: "host",
        timeDate: "7/02 7:14 AM",
        sendBy: "HostBuddy",
      }
    ],
  },
  {
    title: "4Issue Troubleshooting",
    description: "4Hostbuddy can intelligently troubleshoot issues on your behalf based on the property information you provide it.",
    messages: [
      {
        text: "Hello! How can I help you today?",
        sender: "guest",
        timeDate: "9/27 5:17 PM",
        sendBy: "Guest",
      },
      {
        text: "I want to change my account settings.",
        sender: "host",
        timeDate: "9/27 5:18 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "What specific settings would you like to change?",
        sender: "guest",
        timeDate: "9/27 5:19 PM",
        sendBy: "Guest",
      },
      {
        text: "I want to update my password.",
        sender: "host",
        timeDate: "9/27 5:20 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "Please provide your current password and the new password you'd like to set.",
        sender: "guest",
        timeDate: "9/27 5:21 PM",
        sendBy: "Guest",
      },
      {
        text: "My current password is 'currentPass123' and my new password will be 'newPass456'.",
        sender: "host",
        timeDate: "9/27 5:22 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "Your password has been updated successfully. Is there anything else you need help with?",
        sender: "guest",
        timeDate: "9/27 5:23 PM",
        sendBy: "Guest",
      },
    ],
  },
  {
    title: "5Issue Troubleshooting",
    description: "5Hostbuddy can intelligently troubleshoot issues on your behalf based on the property information you provide it.",
    messages: [
      {
        text: "Hi! What assistance do you need today?",
        sender: "guest",
        timeDate: "9/27 5:24 PM",
        sendBy: "Guest",
      },
      {
        text: "I want to report a bug in the application.",
        sender: "host",
        timeDate: "9/27 5:25 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "I'm sorry to hear that. Can you please describe the issue?",
        sender: "guest",
        timeDate: "9/27 5:26 PM",
        sendBy: "Guest",
      },
      {
        text: "The app crashes every time I try to open it.",
        translatedText: "I am stupid and I don't know how to use the app.",
        sender: "host",
        timeDate: "9/27 5:27 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "Thank you for the information. I'll forward this to our technical team immediately.",
        translatedText: "It's too early for this lemme make my tech nerts deal with you.",
        sender: "guest",
        timeDate: "9/27 5:28 PM",
        sendBy: "Guest",
      },
      {
        text: "I hope it gets fixed soon. Thank you!",
        sender: "host",
        timeDate: "9/27 5:29 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "You're welcome! We'll keep you updated on the progress.",
        sender: "guest",
        timeDate: "9/27 5:30 PM",
        sendBy: "Guest",
      },
    ],
  },
  {
    title: "6Issue Troubleshooting",
    description: "6Hostbuddy can intelligently troubleshoot issues on your behalf based on the property information you provide it.",
    messages: [
      {
        text: "Good evening! How can I assist you today?",
        sender: "guest",
        timeDate: "9/27 5:31 PM",
        sendBy: "Guest",
      },
      {
        text: "Can you recommend a product for me?",
        translatedText: "duhhhh idk what to buy",
        sender: "host",
        timeDate: "9/27 5:32 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "I'd like to hear your thoughts.",
        sender: "host",
        timeDate: "9/27 5:32 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "Of course! What type of product are you looking for?",
        sender: "guest",
        timeDate: "9/27 5:33 PM",
        sendBy: "Guest",
      },
      {
        text: "I'm looking for a new laptop.",
        sender: "host",
        timeDate: "9/27 5:34 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "I recommend the XYZ laptop. It's powerful and perfect for both work and gaming!",
        sender: "guest",
        timeDate: "9/27 5:35 PM",
        sendBy: "Guest",
      },
      {
        text: "That sounds great! What are its key features?",
        sender: "host",
        timeDate: "9/27 5:36 PM",
        sendBy: "HostBuddy",
      },
      {
        text: "It has a high-resolution display, fast processing power, and long battery life. Would you like to know more?",
        sender: "guest",
        timeDate: "9/27 5:37 PM",
        sendBy: "Guest",
      },
    ],
  },
];

export default Data;
